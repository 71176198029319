<template>
  <v-menu v-model="dateOpen" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        solo
        flat
        outlined
        dense
        readonly
        hide-details
        v-on="on"
        v-bind="attrs"
        append-icon="mdi-calendar"
        v-model="dateValue">
        <template v-slot:label v-if="required">
          <span class="font-error">{{ requiredText || "Required" }}</span>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dateValue"
      no-title
      scrollable
      color="secondary"
      @click:date="dateOpen = false"
      :min="min" />
  </v-menu>
</template>

<script>
export default {
  props: ["open", "date", "required", "requiredText", "min"],
  computed: {
    dateOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    dateValue: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit("update:date", val);
      },
    },
  },
};
</script>

<style></style>
